var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header",
      class: {
        bg: _vm.headerFixed && !_vm.isMobile,
        bg1: _vm.headerFixed && _vm.isMobile,
        "mobile-height": _vm.isMobile,
      },
    },
    [
      !_vm.isMobile
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _c("img", {
                attrs: { src: "/images/landingPage/logo_white.png", alt: "" },
              }),
              _vm._l(_vm.introductionList, function (introduction) {
                return _c(
                  "div",
                  {
                    key: introduction.key,
                    staticClass: "nav",
                    class: { cur: _vm.curNav === introduction.key },
                    on: {
                      click: function ($event) {
                        return _vm.onClick(introduction.key)
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(introduction.title))])]
                )
              }),
              _c(
                "div",
                {
                  staticClass: "nav",
                  on: {
                    click: function ($event) {
                      return _vm.onClick("yj-offical-website")
                    },
                  },
                },
                [_vm._v(" 亿景官网 ")]
              ),
            ],
            2
          )
        : _c("div", { staticClass: "mobile-content" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: _vm.getImgUrl("logo"), alt: "" },
            }),
            _c("div", { staticClass: "icons" }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.getImgUrl("qrcode_icon"), alt: "" },
                on: {
                  click: function ($event) {
                    return _vm.onClick("qrcode")
                  },
                },
              }),
              _c(
                "a",
                { staticClass: "icon", attrs: { href: "tel:010-86463634" } },
                [
                  _c("img", {
                    attrs: { src: _vm.getImgUrl("phone_icon"), alt: "" },
                  }),
                ]
              ),
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.getImgUrl("nav_icon"), alt: "" },
                on: {
                  click: function ($event) {
                    return _vm.onClick("nav")
                  },
                },
              }),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }