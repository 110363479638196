var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "landing-page-wrap relative" },
    [
      _c("Header", {
        attrs: {
          headerFixed: _vm.headerFixed,
          activeNav: _vm.activeNav,
          isMobile: _vm.isMobile,
        },
        on: {
          "on-select": _vm.onSelectNav,
          "show-qrcode": _vm.onShowQrcode,
          "show-mobile-nav": _vm.onShowMobileNav,
        },
      }),
      _c(
        "div",
        {
          ref: "scrollview",
          staticClass: "landing-page",
          on: { scroll: _vm.watchScroll },
        },
        [
          _c("div", { staticClass: "banner", attrs: { id: "banner" } }, [
            _c("div", { staticClass: "content" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.banner.title)),
              ]),
              _c("p", { staticClass: "desc" }, [
                _vm._v(_vm._s(_vm.banner.desc)),
              ]),
              _c(
                "div",
                { staticClass: "btn cur-pointer", on: { click: _vm.toIndex } },
                [
                  _c("span", [_vm._v("立即体验")]),
                  !_vm.isMobile
                    ? _c("img", {
                        staticClass: "arrow",
                        attrs: {
                          src: _vm.getImgUrl("arrow_right_white"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "total bg1" }, [
            _c("div", { staticClass: "relative total-content" }, [
              _c(
                "div",
                { staticClass: "content absolute display-flex flex-wrap-wrap" },
                _vm._l(_vm.totalList, function (total) {
                  return _c(
                    "div",
                    {
                      key: total.key,
                      staticClass: "item display-flex align-items-center",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.getImgUrl(`total_${total.key}`),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "right" }, [
                        !_vm.isMobile
                          ? _c("p", { staticClass: "title" }, [
                              _vm._v(_vm._s(total.title)),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "num" }, [
                          _vm._v(" " + _vm._s(total.num) + " "),
                          _c("span", [_vm._v(_vm._s(total.unit))]),
                        ]),
                        _vm.isMobile
                          ? _c("p", { staticClass: "title" }, [
                              _vm._v(_vm._s(total.title)),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm._l(_vm.introductionList, function (introduction, index) {
            return _c(
              "div",
              {
                key: introduction.key,
                staticClass: "introduction relative",
                class: {
                  bg1: index % 2 === 0,
                  bg2: index % 2 !== 0,
                },
                attrs: { id: !_vm.isMobile ? introduction.key : "" },
              },
              [
                _c("div", {
                  staticClass: "mobile-id absolute",
                  attrs: { id: _vm.isMobile ? introduction.key : "" },
                }),
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(introduction.title)),
                ]),
                _c("p", { staticClass: "desc" }, [
                  _vm._v(_vm._s(introduction.desc)),
                ]),
                _c("p", { staticClass: "en-title absolute" }, [
                  _vm._v(_vm._s(introduction.enTitle)),
                ]),
                _c("div", { staticClass: "content" }, [
                  introduction.key === "product"
                    ? _c(
                        "div",
                        { staticClass: "product-service" },
                        _vm._l(
                          _vm.productList,
                          function (product, productIndex) {
                            return _c(
                              "div",
                              {
                                key: product.key,
                                staticClass: "product-item",
                                style: {
                                  height: !_vm.isMobile
                                    ? product.height
                                    : "auto",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "product-text",
                                    class: {
                                      fr:
                                        productIndex % 2 !== 0 && !_vm.isMobile,
                                      fl:
                                        productIndex % 2 === 0 && !_vm.isMobile,
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "title",
                                        class: { "mb-20": _vm.isMobile },
                                      },
                                      [_vm._v(_vm._s(product.title))]
                                    ),
                                    _c(
                                      "div",
                                      _vm._l(
                                        product.desc,
                                        function (desc, descIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: "desc_" + descIndex,
                                              staticClass: "desc",
                                            },
                                            [_vm._v(" " + _vm._s(desc) + " ")]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "tags" },
                                      _vm._l(
                                        product.tags,
                                        function (tag, tagIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: "tag_" + tagIndex,
                                              staticClass: "tag",
                                            },
                                            [_vm._v(" " + _vm._s(tag) + " ")]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _c("img", {
                                  class: {
                                    fr: productIndex % 2 === 0 && !_vm.isMobile,
                                    fl: productIndex % 2 !== 0 && !_vm.isMobile,
                                  },
                                  attrs: {
                                    src: _vm.getImgUrl(
                                      `product_${product.key}`
                                    ),
                                  },
                                }),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : introduction.key === "data"
                    ? _c("div", { staticClass: "data-capability" }, [
                        !_vm.isMobile
                          ? _c(
                              "div",
                              _vm._l(_vm.dataList, function (data) {
                                return _c("div", { key: data.key }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "subtitle",
                                      class: {
                                        "mt-70": data.key === "product",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "relative" }, [
                                        _vm._v(_vm._s(data.title)),
                                      ]),
                                    ]
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: _vm.getImgUrl(`data_${data.key}`),
                                      alt: "",
                                    },
                                  }),
                                ])
                              }),
                              0
                            )
                          : _c(
                              "div",
                              _vm._l(_vm.dataList, function (data) {
                                return _c(
                                  "div",
                                  { key: data.key },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "subtitle",
                                        style: {
                                          marginTop:
                                            data.key === "index"
                                              ? "0.2rem"
                                              : "4rem",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "relative" },
                                          [
                                            _c("img", {
                                              staticClass: "icon absolute",
                                              attrs: {
                                                src: _vm.getImgUrl(
                                                  "subtitle_icon"
                                                ),
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(
                                              " " + _vm._s(data.title) + " "
                                            ),
                                            _c("img", {
                                              staticClass: "icon1 absolute",
                                              attrs: {
                                                src: _vm.getImgUrl(
                                                  "subtitle_icon"
                                                ),
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    data.key === "index"
                                      ? _c("Swiper", {
                                          attrs: {
                                            imgs: _vm.getSwiperImgs(
                                              `data_${data.key}`,
                                              3
                                            ),
                                            interval: 3000,
                                          },
                                        })
                                      : _c("img", {
                                          attrs: {
                                            src: _vm.getImgUrl(
                                              `data_${data.key}`
                                            ),
                                            alt: "",
                                          },
                                        }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                      ])
                    : introduction.key === "industry"
                    ? _c("div", { staticClass: "industry-solutions" }, [
                        _c(
                          "div",
                          {
                            class: {
                              "home-main": !_vm.isMobile,
                              "display-flex": !_vm.isMobile,
                            },
                          },
                          [
                            _c("div", { staticClass: "left relative" }, [
                              _vm.isMobile
                                ? _c("img", {
                                    staticClass: "absolute",
                                    attrs: {
                                      src: _vm.getImgUrl("industry_bg"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _c("p", { staticClass: "title relative" }, [
                                _vm._v(_vm._s(_vm.industryObj.left.title)),
                              ]),
                              _c(
                                "div",
                                {
                                  class: {
                                    "display-flex": _vm.isMobile,
                                    "flex-wrap-wrap": _vm.isMobile,
                                  },
                                },
                                _vm._l(
                                  _vm.industryObj.left.content,
                                  function (bm, bmIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: "bm_" + bmIndex,
                                        staticClass: "item relative",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-left" },
                                          [
                                            _c("img", {
                                              staticClass: "circle",
                                              attrs: {
                                                src: _vm.getImgUrl(
                                                  "industry_circle"
                                                ),
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-right" },
                                          [_vm._v(_vm._s(bm))]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "right" },
                              _vm._l(
                                _vm.industryObj.right,
                                function (industry, industryIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: "industry_" + industryIndex,
                                      staticClass: "item relative",
                                      class: {
                                        "mb-25":
                                          !_vm.isMobile && industryIndex < 2,
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "left-icon absolute",
                                        attrs: {
                                          src: _vm.getImgUrl("industry_icon"),
                                          alt: "",
                                        },
                                      }),
                                      _c("img", {
                                        staticClass: "right-icon absolute",
                                        attrs: {
                                          src: _vm.getImgUrl(
                                            `industry_${industry.key}`
                                          ),
                                          alt: "",
                                        },
                                      }),
                                      _c("p", { staticClass: "title mb-20" }, [
                                        _vm._v(_vm._s(industry.title)),
                                      ]),
                                      _c("div", { staticClass: "content" }, [
                                        _vm._v(_vm._s(industry.content)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ])
                    : introduction.key === "case"
                    ? _c("div", { staticClass: "customer-cases" }, [
                        _c(
                          "div",
                          {
                            ref: "caseHeader",
                            refInFor: true,
                            staticClass: "case-header",
                          },
                          _vm._l(_vm.caseList, function (cusCase, caseIndex) {
                            return _c(
                              "div",
                              {
                                key: cusCase.key,
                                staticClass: "case-item",
                                class: {
                                  active: caseIndex === _vm.caseCurIndex,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onChangeCase(caseIndex)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "case-img",
                                  attrs: {
                                    src: _vm.getImgUrl(
                                      `${cusCase.key}${
                                        caseIndex === _vm.caseCurIndex
                                          ? "_active"
                                          : ""
                                      }_icon`
                                    ),
                                    alt: "",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "case-title",
                                    class: {
                                      active: caseIndex === _vm.caseCurIndex,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(cusCase.title) + " ")]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "case-content relative" }, [
                          _c("img", {
                            staticClass: "case-img",
                            attrs: {
                              src: _vm.getImgUrl(
                                `${_vm.caseCurContent.key}_bg`
                              ),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "right relative" }, [
                            _c(
                              "div",
                              { staticClass: "right-content" },
                              [
                                _c("p", { staticClass: "title" }, [
                                  _vm._v("项目背景"),
                                ]),
                                _c("div", { staticClass: "item mb-20" }, [
                                  _vm._m(0, true),
                                  _c("div", { staticClass: "item-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.caseCurContent.projectBackground
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("p", { staticClass: "title" }, [
                                  _vm._v("解决方案"),
                                ]),
                                _vm.caseCurContent.solution?.title
                                  ? _c("div", { staticClass: "item" }, [
                                      _c("div", { staticClass: "item-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.caseCurContent.solution.title
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._l(
                                  _vm.caseCurContent.solution.content,
                                  function (solution, solutionIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: "solution_" + solutionIndex,
                                        staticClass: "item",
                                      },
                                      [
                                        _vm._m(1, true),
                                        _c(
                                          "div",
                                          { staticClass: "item-right" },
                                          [_vm._v(_vm._s(solution))]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _c("p", { staticClass: "title mt-20" }, [
                                  _vm._v("客户收益"),
                                ]),
                                _c("div", { staticClass: "item" }, [
                                  _vm._m(2, true),
                                  _c("div", { staticClass: "item-right" }, [
                                    _vm._v(
                                      _vm._s(_vm.caseCurContent.customerEarning)
                                    ),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "service-customer" }, [
                          _c("div", { staticClass: "subtitle" }, [
                            _c("span", { staticClass: "relative" }, [
                              _vm.isMobile
                                ? _c("img", {
                                    staticClass: "icon absolute",
                                    attrs: {
                                      src: _vm.getImgUrl("subtitle_icon"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" 服务客户 "),
                              _vm.isMobile
                                ? _c("img", {
                                    staticClass: "icon1 absolute",
                                    attrs: {
                                      src: _vm.getImgUrl("subtitle_icon"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "rolling-wrap relative" }, [
                            !_vm.isMobile
                              ? _c("img", {
                                  staticClass: "mask1 absolute",
                                  attrs: {
                                    src: _vm.getImgUrl("service_mask"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _c("div", { staticClass: "rolling-img" }, [
                              _c("img", {
                                staticClass: "rolling",
                                attrs: {
                                  src: _vm.getImgUrl("service_customer"),
                                  alt: "",
                                },
                              }),
                              _c("img", {
                                staticClass: "rolling rolling2",
                                attrs: {
                                  src: _vm.getImgUrl("service_customer"),
                                  alt: "",
                                },
                              }),
                            ]),
                            !_vm.isMobile
                              ? _c("img", {
                                  staticClass: "mask2 absolute",
                                  attrs: {
                                    src: _vm.getImgUrl("service_mask"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]
            )
          }),
          _c("div", { staticClass: "free-trial relative" }, [
            _c("img", {
              staticClass: "absolute",
              attrs: { src: _vm.getImgUrl("free_trial"), alt: "" },
            }),
            _c("div", { staticClass: "content relative" }, [
              _vm._m(3),
              _c(
                "div",
                {
                  staticClass:
                    "trial-btn cur-pointer display-flex align-items-center",
                  on: { click: _vm.toIndex },
                },
                [
                  _c("span", [_vm._v("开始免费使用")]),
                  !_vm.isMobile
                    ? _c("img", {
                        staticClass: "arrow",
                        attrs: {
                          src: _vm.getImgUrl("arrow_right_white_double"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
          _c("Footer", { attrs: { isMobile: _vm.isMobile } }),
        ],
        2
      ),
      !_vm.isMobile
        ? _c(
            "div",
            { staticClass: "contact-panel1" },
            [
              _c(
                "Tooltip",
                {
                  staticClass: "tooltip",
                  attrs: { placement: "left", theme: "light" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "phone" }, [
                              _c("p", { staticClass: "text" }, [
                                _vm._v("咨询热线"),
                              ]),
                              _c("p", { staticClass: "phone-bumber" }, [
                                _vm._v("010-86463634"),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2828494479
                  ),
                },
                [_c("div", { staticClass: "icon icon1" })]
              ),
              _c(
                "Tooltip",
                {
                  staticClass: "tooltip",
                  attrs: { placement: "left", theme: "light" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "qrcode" },
                              _vm._l(_vm.qrcodeList, function (qrcode, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "qrcode_" + index,
                                    staticClass: "qrcode-item",
                                  },
                                  [
                                    _c("img", {
                                      class: "img" + index,
                                      attrs: { src: qrcode.url, alt: "" },
                                    }),
                                    _c("div", [_vm._v(_vm._s(qrcode.text))]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2662930521
                  ),
                },
                [_c("div", { staticClass: "icon icon2" })]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isShowQrcode
        ? _c("div", { staticClass: "qrcode-wrap absolute" }, [
            _c("div", { staticClass: "qrcode" }, [
              _c("img", { attrs: { src: _vm.getImgUrl("qrcode"), alt: "" } }),
              _c("div", [_vm._v("千里目小助手")]),
            ]),
            _c("img", {
              staticClass: "close_btn",
              attrs: { src: _vm.getImgUrl("close_white"), alt: "" },
              on: {
                click: function ($event) {
                  return _vm.onShowQrcode(false)
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.isShowMobileNav
        ? _c("div", { staticClass: "nav-wrap absolute" }, [
            _c("div", {
              staticClass: "blank absolute",
              on: {
                click: function ($event) {
                  return _vm.onShowMobileNav(false)
                },
              },
            }),
            _c("div", { staticClass: "nav" }, [
              _c(
                "ul",
                [
                  _vm._l(_vm.introductionList, function (introduction) {
                    return _c("li", { key: introduction.key }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `#${introduction.key}`,
                            target: "_self",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onSelectNav(introduction.key)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(introduction.title) + " ")]
                      ),
                    ])
                  }),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://changjing.com.cn",
                          target: "_blank",
                        },
                        on: { click: _vm.onSelectNav },
                      },
                      [_vm._v(" 亿景官网 ")]
                    ),
                  ]),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item-left" }, [
      _c("div", { staticClass: "circle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item-left" }, [
      _c("div", { staticClass: "circle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item-left" }, [
      _c("div", { staticClass: "circle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "title" }, [
      _c("span", [_vm._v("搜索商业数据")]),
      _c("span", [_vm._v("发现商业机会")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }