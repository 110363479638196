var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "swiper",
        { staticClass: "swiper", attrs: { options: _vm.swiperOption } },
        [
          _vm._l(_vm.imgs, function (image) {
            return _c(
              "swiper-slide",
              { key: image, staticClass: "swiper-slide" },
              [_c("img", { attrs: { src: image, alt: "" } })]
            )
          }),
          _c("div", {
            staticClass: "swiper-pagination",
            attrs: { slot: "pagination" },
            slot: "pagination",
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }