<template>
  <div class="landing-page-wrap relative">
    <Header
      :headerFixed="headerFixed"
      :activeNav="activeNav"
      :isMobile="isMobile"
      @on-select="onSelectNav"
      @show-qrcode="onShowQrcode"
      @show-mobile-nav="onShowMobileNav"
    ></Header>

    <div class="landing-page" ref="scrollview" @scroll="watchScroll">
      <div class="banner" id="banner">
        <div class="content">
          <p class="title">{{ banner.title }}</p>
          <p class="desc">{{ banner.desc }}</p>
          <div class="btn cur-pointer" @click="toIndex">
            <span>立即体验</span>
            <img class="arrow" :src="getImgUrl('arrow_right_white')" alt="" v-if="!isMobile" />
          </div>
        </div>
      </div>

      <div class="total bg1">
        <div class="relative total-content">
          <div class="content absolute display-flex flex-wrap-wrap">
            <div
              v-for="total in totalList"
              :key="total.key"
              class="item display-flex align-items-center"
            >
              <img :src="getImgUrl(`total_${total.key}`)" alt="" />
              <div class="right">
                <p class="title" v-if="!isMobile">{{ total.title }}</p>
                <div class="num">
                  {{ total.num }}
                  <span>{{ total.unit }}</span>
                </div>
                <p class="title" v-if="isMobile">{{ total.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(introduction, index) in introductionList"
        :key="introduction.key"
        :id="!isMobile ? introduction.key : ''"
        class="introduction relative"
        :class="{
          bg1: index % 2 === 0,
          bg2: index % 2 !== 0
        }"
      >
        <div
          :id="isMobile ? introduction.key : ''"
          class="mobile-id absolute"
        ></div>
        <p class="title">{{ introduction.title }}</p>
        <p class="desc">{{ introduction.desc }}</p>
        <p class="en-title absolute">{{ introduction.enTitle }}</p>
        <div class="content">
          <div v-if="introduction.key === 'product'" class="product-service">
            <div
              v-for="(product, productIndex) in productList"
              :key="product.key"
              class="product-item"
              :style="{height: !isMobile ? product.height : 'auto'}"
            >
              <div
                class="product-text"
                :class="{
                  fr: productIndex % 2 !== 0 && !isMobile,
                  fl: productIndex % 2 === 0 && !isMobile
                }"
              >
                <p class="title" :class="{'mb-20': isMobile}">{{ product.title }}</p>
                <div>
                  <div
                    v-for="(desc, descIndex) in product.desc"
                    :key="'desc_' + descIndex"
                    class="desc"
                  >
                    {{ desc }}
                  </div>
                </div>
                <div class="tags">
                  <div
                    v-for="(tag, tagIndex) in product.tags"
                    :key="'tag_' + tagIndex"
                    class="tag"
                  >
                    {{ tag }}
                  </div>
                </div>
              </div>
              <img
                :src="getImgUrl(`product_${product.key}`)"
                :class="{
                  fr: productIndex % 2 === 0 && !isMobile,
                  fl: productIndex % 2 !== 0 && !isMobile
                }"
              />
            </div>
          </div>
          <div v-else-if="introduction.key === 'data'" class="data-capability">
            <div v-if="!isMobile">
              <div v-for="data in dataList" :key="data.key">
                <div class="subtitle" :class="{'mt-70': data.key === 'product'}">
                  <span class="relative">{{ data.title }}</span>
                </div>
                <img :src="getImgUrl(`data_${data.key}`)" alt="" />
              </div>
            </div>
            <div v-else>
              <div v-for="data in dataList" :key="data.key">
                <div class="subtitle" :style="{marginTop: data.key === 'index' ? '0.2rem' : '4rem'}">
                  <span class="relative">
                    <img class="icon absolute" :src="getImgUrl('subtitle_icon')" alt="" />
                    {{ data.title }}
                    <img class="icon1 absolute" :src="getImgUrl('subtitle_icon')" alt="" />
                  </span>
                </div>
                <Swiper :imgs="getSwiperImgs(`data_${data.key}`, 3)" :interval="3000" v-if="data.key ==='index'"></Swiper>
                <img :src="getImgUrl(`data_${data.key}`)" alt="" v-else />
              </div>
            </div>
          </div>
          <div v-else-if="introduction.key === 'industry'" class="industry-solutions">
            <div 
              :class="{
                'home-main': !isMobile,
                'display-flex': !isMobile
              }"
            >
              <div class="left relative">
                <img class="absolute" :src="getImgUrl('industry_bg')" alt="" v-if="isMobile" />
                <p class="title relative">{{ industryObj.left.title }}</p>
                <div
                  :class="{
                    'display-flex': isMobile,
                    'flex-wrap-wrap': isMobile
                  }"
                >
                  <div
                    v-for="(bm, bmIndex) in industryObj.left.content"
                    :key="'bm_' + bmIndex"
                    class="item relative"
                  >
                    <div class="item-left">
                      <img class="circle" :src="getImgUrl('industry_circle')" alt="">
                    </div>
                    <div class="item-right">{{ bm }}</div>
                  </div>
              </div>
              </div>
              <div class="right">
                <div
                  v-for="(industry, industryIndex) in industryObj.right"
                  :key="'industry_' + industryIndex"
                  class="item relative"
                  :class="{'mb-25': !isMobile && industryIndex < 2}"
                >
                  <img class="left-icon absolute" :src="getImgUrl('industry_icon')" alt="" />
                  <img class="right-icon absolute" :src="getImgUrl(`industry_${industry.key}`)" alt="" />
                  <p class="title mb-20">{{ industry.title }}</p>
                  <div class="content">{{ industry.content }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="introduction.key === 'case'" class="customer-cases">
            <div class="case-header" ref="caseHeader">
              <div
                v-for="(cusCase, caseIndex) in caseList"
                :key="cusCase.key"
                class="case-item"
                :class="{active: caseIndex === caseCurIndex}"
                @click="onChangeCase(caseIndex)"
              >
                <img class="case-img" :src="getImgUrl(`${cusCase.key}${caseIndex === caseCurIndex ? '_active' : ''}_icon`)" alt="" />
                <span
                  class="case-title"
                  :class="{active: caseIndex === caseCurIndex}"
                >
                  {{ cusCase.title }}
                </span>
              </div>
            </div>
            <div class="case-content relative">
              <img class="case-img" :src="getImgUrl(`${caseCurContent.key}_bg`)" alt="" />
              <div class="right relative">
                <div class="right-content">
                  <p class="title">项目背景</p>
                  <div class="item mb-20">
                    <div class="item-left">
                      <div class="circle"></div>
                    </div>
                    <div class="item-right">{{ caseCurContent.projectBackground }}</div>
                  </div>
                  <p class="title">解决方案</p>
                  <div class="item" v-if="caseCurContent.solution?.title">
                    <div class="item-right">{{ caseCurContent.solution.title }}</div>
                  </div>
                  <div
                    v-for="(solution, solutionIndex) in caseCurContent.solution.content"
                    :key="'solution_' + solutionIndex"
                    class="item"
                  >
                    <div class="item-left">
                      <div class="circle"></div>
                    </div>
                    <div class="item-right">{{ solution }}</div>
                  </div>
                  <p class="title mt-20">客户收益</p>
                  <div class="item">
                    <div class="item-left">
                      <div class="circle"></div>
                    </div>
                    <div class="item-right">{{ caseCurContent.customerEarning }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="service-customer">
              <div class="subtitle">
                <span class="relative">
                  <img class="icon absolute" :src="getImgUrl('subtitle_icon')" alt="" v-if="isMobile" />
                  服务客户
                  <img class="icon1 absolute" :src="getImgUrl('subtitle_icon')" alt="" v-if="isMobile" />
                </span>
              </div>
              <div class="rolling-wrap relative">
                <img class="mask1 absolute" :src="getImgUrl('service_mask')" alt="" v-if="!isMobile" />
                <div class="rolling-img">
                  <img class="rolling" :src="getImgUrl('service_customer')" alt="" />
                  <img class="rolling rolling2" :src="getImgUrl('service_customer')" alt="" />
                </div>
                <img class="mask2 absolute" :src="getImgUrl('service_mask')" alt="" v-if="!isMobile" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="free-trial relative">
        <img class="absolute" :src="getImgUrl('free_trial')" alt="" />
        <div class="content relative">
          <p class="title">
            <span>搜索商业数据</span>
            <span>发现商业机会</span>
          </p>
          <div class="trial-btn cur-pointer display-flex align-items-center" @click="toIndex">
            <span>开始免费使用</span>
            <img class="arrow" :src="getImgUrl('arrow_right_white_double')" alt="" v-if="!isMobile" />
          </div>
        </div>
      </div>

      <Footer :isMobile="isMobile"></Footer>
    </div>

    <div class="contact-panel1" v-if="!isMobile">
      <Tooltip placement="left" theme="light" class="tooltip">
        <div class="icon icon1"></div>
        <template #content>
          <div class="phone">
            <p class="text">咨询热线</p>
            <p class="phone-bumber">010-86463634</p>
          </div>
        </template>
      </Tooltip>
      <Tooltip placement="left" theme="light" class="tooltip">
        <div class="icon icon2"></div>
        <template #content>
          <div class="qrcode">
            <div
              v-for="(qrcode, index) in qrcodeList"
              :key="'qrcode_' + index"
              class="qrcode-item"
            >
              <img :class="'img' + index" :src="qrcode.url" alt="" />
              <div>{{ qrcode.text }}</div>
            </div>
          </div>
        </template>
      </Tooltip>
    </div>

    <div class="qrcode-wrap absolute" v-if="isShowQrcode">
      <div class="qrcode">
        <img :src="getImgUrl('qrcode')" alt="" />
        <div>千里目小助手</div>
      </div>
      <img
        class="close_btn"
        :src="getImgUrl('close_white')"
        alt=""
        @click="onShowQrcode(false)"
      />
    </div>

    <div class="nav-wrap absolute" v-if="isShowMobileNav">
      <div class="blank absolute" @click="onShowMobileNav(false)"></div>
      <div class="nav">
        <ul>
          <li
            v-for="introduction in introductionList"
            :key="introduction.key"
          >
            <a
              :href="`#${introduction.key}`"
              :target="'_self'"
              @click="onSelectNav(introduction.key)"
            >
              {{ introduction.title }}
            </a>
          </li>
          <li>
            <a
              href="https://changjing.com.cn"
              :target="'_blank'"
              @click="onSelectNav"
            >
              亿景官网
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { getDprImg } from "@/assets/javascript/utils.js";
  import Header from "@/components/home/Header.vue";
  import Footer from "@/components/home/Footer.vue";
  import Swiper from "@/components/home/Swiper.vue";
  export default {
    metaInfo: {
      title: "千里目 - 全域商业数据平台",
      meta: [
        {
          name: "keywords",
          content:
            "千里目,大数据,商业数据,数据查询,数据分析,数据可视化,用户画像,实时客流,客流统计,品牌监测,品牌销量,业态分布,周边配套,广告创意投放,人口热力图分布,商户分析,商业营销,全域数据,全国数据,时空大数据,产业洞察,门店洞察,开店选址,开关店数据,竞争对手分析,对比分析,深度分析,分析报告,数据上图,品牌排行,智能查询,商业决策,数字化",
        },
        {
          name: "description",
          content:
            "千里目全域商业数据平台，提供查品牌、查门店、查位置、查商圈、查商场、查城市、查客流、查周边、查创意等全域多维数据的查询分析服务，以数据驱动商业发展和决策，助力企业发现商业机会。",
        },
      ],
    },
    components: {
      Header,
      Footer,
      Swiper
    },
    data () {
      return {
        banner: {
          title: "千里目，全域商业数据平台",
          desc: "品牌分析｜ 门店洞察｜ 周边查询｜ 商圈洞察｜ 选址报告｜ 客流到访"
        },
        totalList: [
          {
            key: "user",
            title: "覆盖用户",
            num: 9.5,
            unit: "亿+"
          },
          {
            key: "geom",
            title: "地理画像",
            num: 1.5,
            unit: "亿+"
          },
          {
            key: "store",
            title: "品牌门店",
            num: 800,
            unit: "万+"
          },
          {
            key: "brand",
            title: "品牌",
            num: 15,
            unit: "万+"
          },
          {
            key: "city",
            title: "城市",
            num: 367,
            unit: ""
          }
        ],
        productList: [
          {
            key: "brand",
            title: "品牌商业数据洞察",
            desc: [
              "企业数据：所属公司、行业类型、工商资质，经营模式等。",
              "线下数据：门店分布、周边客群、到访分析、客户画像、门店销量等。",
              "线上数据：投放趋势、媒体偏好、投放效果、以及视频归因分析等。"
            ],
            tags: ["品牌商业报告", "开店选址评分", "竞品分析", "线上营销洞察", "市场调研", "客群画像"],
            height: "495px"
          },
          {
            key: "store",
            title: "品牌门店",
            desc: [
              "区域信息：覆盖人群、公建配套、业态分布等。",
              "客群到访：到访分析、停留时长、客户画像等。",
              "品类销量：热销品类、销量趋势、消费客群来源等。"
            ],
            tags: ["门店周边配套", "周边竞争评估", "门店到访人群", "消费人群来源", "门店销量分析", "周边客群分析"],
            height: "468px"
          },
          {
            key: "location",
            title: "区域位置",
            desc: [
              "配套信息：写字楼、住宅、学校、公司、商业、医院、交通的分布，以及平均房价等。",
              "客群特征：人口规模、人群基础信息、人群特征画像等。",
              "业态分布：餐饮、零售、生活服务、宾馆酒店、医疗保健、体育休闲、金融银行等分布。"
            ],
            tags: ["小区分布", "写字楼分布", "人口结构洞察", "商业业态分布", "常驻人群品牌偏好", "交通状况"],
            height: "484px"
          },
          {
            key: "mall",
            title: "商场/商圈洞察",
            desc: [
              "商圈：清晰呈现商圈内，商圈级别、商业项目、占地面积、入驻品牌、客群画像、 客流分析、人群线下行为轨迹，周边基础设施等数据。",
              "商场：商场的基础信息，也可以深度洞察并分析商场区位、业态购成、商户分布、周边客群分析、客流分析等数据。"
            ],
            tags: ["商圈到访趋势", "周边客群分析", "区域配套洞察", "聚客类型洞察"],
            height: "482px"
          },
          {
            key: "city",
            title: "城市洞察",
            desc: [
              "综合经济：GDP、人均可支配收入、人均消费支出、消费品零售总额等。",
              "商业数据：城市商业成熟度、商圈/购物中心分布、商业中心面积，人均占有量等。",
              "城市交通：公交线路、地铁、地铁站、火车站、机场基础信息及地图点位分布等。"
            ],
            tags: ["综合经济报告", "城市商业项目分布", "城市交通分布洞察"],
            height: "479px"
          }
        ],
        dataList: [
          {
            key: "index",
            title: "指标维度"
          },
          {
            key: "product",
            title: "产品结构"
          }
        ],
        industryObj: {
          left: {
            title: "品牌营销",
            content: [
              "触达客群特征画像",
              "门店数字营销地图",
              "目标选址智能评级",
              "周边商业生态指数",
              "营销广告投流数据",
              "挖掘市场潜在机会",
              "竞品/店监测洞察"
            ]
          },
          right: [
            {
              key: "restaurant",
              title: "餐饮连锁",
              content: "全面了解区域内业态分布与客流规模，判断区域内消费者消费偏好, 挖掘位置数据商业价值，提供从“选址-开店-运营-营销”的全链路服务。"
            },
            {
              key: "house",
              title: "商业地产",
              content: "通过商业规划分析、城市宏观数据、地理位置信息等能力辅助地产企业将多业态、多元化用户和场景信息数字化，在土地商务投拓，用地分析上打造配套完善、提供了辅助决策支持。"
            },
            {
              key: "retail",
              title: "品牌零售",
              content: "提供区域竞争监测能力，及时了解门店周边客流趋势，聚焦高频交易和高覆盖用户的连锁零售场景。服务内容包括：门店选址，商址评估，客群行为洞察，竞品分析，精准营销等。"
            },
            {
              key: "bank",
              title: "银行金融",
              content: "通过网点业务数据化，为银行等金融机构建立完善的数据资产地图，通过数据模型算法将银行的产品服务与场景深度融合，解决差异化区域经营难题与网点布局优化等问题。"
            }
          ]
        },
        caseList: [
          {
            key: "case_restaurant",
            title: "餐饮行业",
            projectBackground: "客户公司为某餐饮连锁品牌，目前已上市进入扩张阶段，希望以某一线城市为核心，预估市场机会，拓展更多连锁加盟，进行开店选址分析，构建门店智能选址分析模型，为品牌业务扩张、门店选址、提供数据支撑，辅助战略及业务决策。",
            solution: {
              title: "门店洞察+地理建模+数据分析，实现智能门店选址模型：",
              content: [
                "深度洞察现有门店经营效能影响因素，智能化评估门店投资回报水平和经营成长性。",
                "通过门店现有位置及历史经营水平，进行机器学习建模，并验证模型的预测情况。",
                "提炼影响现有门店经营水平的选址关键指标，辅助新开门店选址落地可行性。",
                "分析当前地区用户的习惯、常驻和流动人员分布、客群画像、周边竞争态势、交通便利性等数据， 判断某个地点是否适合开店，并给出关于在该地店的评分。"
              ]
            },
            customerEarning: "为客户在某一线城市地区选址拓店，提供了数据支持和智能化的决策依据。比传统的依靠调研和专家经验的方式大幅提高了决策效率和准确率。通过分析当地行业市场，人群消费偏好等数据，为品牌业务扩张提供市场调研决策数据。"
          },
          {
            key: "case_retail",
            title: "零售行业",
            projectBackground: "客户公司为某零售连锁品牌，该品牌在28个省市已发展近1133家门店，门店陷入增长困境，需要对门店评级分类，挖掘业务影响关键因子，制定策略实现差异化经营，依据市场归因实现销量预测，需求合作渠道拓展、选品优化提升经营效率等。",
            solution: {
              content: [
                "依赖千里目大数据引擎对多门店数据进行汇总，实现时空大数据的统一视图的管理，解决了数据汇总和信息化管理的问题。",
                "利用地理商业智能引擎的地理编码模块实现快捷的营业网点数据上图，利用平台便捷的构建业务作战地图，实现一站式品牌市场洞察。",
                "综合门店与商圈数据、宏观经济数据，测算不同省份、城市的资源投放优先级与单个门店的指数，为销售策略制定提供参考。"
              ]
            },
            customerEarning: "有效帮助品牌客户总计1133门店，进行数字化智能化运营。在门店评级、旺店评估、销售预测、归因分析、运营优化和营销优化等方面，提供线下大数据和智能预测结果辅助决策，有效指导门店运营优化。" 
          },
          {
            key: "case_house",
            title: "地产行业",
            projectBackground: "客户公司为某头部地产品牌，业务痛点是：缺少有效的投拓拿地分析，不能高效将信息数据整合；缺少政府对于土地开发的规划，以及数据支持市场投拓业务。",
            solution: {
              content: [
                "依赖千里目的地理商业智能引擎，支持智能地产行业投拓数据分析，在土地商务投拓，用地分析上提供了辅助决策支持。",
                "具体实现了规划数据建设，业务数据归档，目标地块智能查询，精准营销专题地图，利用统一时空计算模型实现了地块智能评分，利用高效的可视化渲染能力实现了友好的客户交互，有效的提升了地产投拓决策效率。",
                "根据城市宏观数据进行城市建设规划分析和区域配套，人口画像，消费便好等数据整合。"
              ]
            },
            customerEarning: "补全了辅助决策支持的数据资源。提供的周边情况信息以及地块智能评分提升了决策效率。高性能可视化可以更直观地对于整个市场的宏观布局提供鹰眼透视能力。" 
          }
        ],
        qrcodeList: [
          {
            url: "/images/qrcode.png",
            text: "千里目小助手"
          },
          {
            url: "/images/yj_qrcode.png",
            text: "关注公众号"
          },
          {
            url: "/images/home/qrcode.png",
            text: "千里目小程序"
          }
        ],
        caseCurIndex: 0,
        caseCurContent: {},
        scrollview: null,
        headerFixed: false,
        activeNav: "",
        isShowQrcode: false,
        isShowMobileNav: false,
        scrollTop: null,
        caseHeaderItemsOffset: [],
        caseHeaderOffsetLeft: 0,
        caseHeaderOffsetWidth: 0,
      }
    },
    computed: {
      ...mapState(["introductionList"]),
      isMobile() {
        return this.$global.isMobile;
      }
    },
    methods: {
      getImgUrl(path) {
        return this.isMobile
                ? getDprImg(`/images/landingPage/mobile/${path}.png`)
                : `/images/landingPage/${path}.png`;
      },
      getSwiperImgs(type, num) {
        let imgs = [];
        for (let i = 1; i <= num; i++) {
          imgs.push(this.getImgUrl(`${type}${i}`))
        }
        return imgs;
      },
      onSelectNav(name) {
        if(this.isMobile) {
          this.isShowMobileNav = false;
        } else {
          document.getElementById(name) &&
            document.getElementById(name).scrollIntoView({ behavior: "smooth" });
        }
      },
      onShowQrcode(val) {
        this.isShowQrcode = val;
      },
      onShowMobileNav(val) {
        this.isShowMobileNav = val;
      },
      onChangeCase(index) {
        this.caseCurIndex = index;
        this.caseCurContent = this.caseList[this.caseCurIndex];
        this.scrollDom(index);
      },
      toIndex() {
        if (this.isMobile) {
          location.href = `${location.origin}/bridge.html`;
        } else {
          const { href } = this.$router.resolve({
            path: "/index"
          });
          window.open(href, "_blank");
        }
      },
      watchScroll() {
        let scrollTop = this.$refs.scrollview.scrollTop;
        this.headerFixed = this.isMobile ? (scrollTop / this.$global.fontSize > 3) : scrollTop > 100;

        let total = 0;
        let heightList = [];
        let bannerHeight = document.getElementById("banner").offsetHeight;
        heightList.push(bannerHeight)
        let introductionHeightList = document.getElementsByClassName("introduction");
        for(let i = 0; i < introductionHeightList.length; i++) {
          heightList.push(introductionHeightList[i].offsetHeight);
        }
        heightList = heightList.map((ele, index) => {
          total += ele;
          return index === heightList.length - 1 ? total - 82 : total - 2; // 防止锚点定位出现偏差
        });
        let moduleName = this.introductionList.map(item => item.key);
        for(let i = 0; i < heightList.length; i++) {
          if (scrollTop > heightList[i] && scrollTop < heightList[i + 1]) {
            this.activeNav = moduleName[i];
          } else if (scrollTop <= heightList[0] || scrollTop > heightList[heightList.length - 1]) {
            this.activeNav = "";
          }
        }
      },
      initCaseScrollData() {
        let caseHeader = document.getElementsByClassName("case-header");
        this.caseHeaderOffsetLeft = caseHeader?.[0].offsetLeft;
        this.caseHeaderOffsetWidth = caseHeader?.[0].offsetWidth;
        let caseHeaderItems = document.getElementsByClassName("case-item");
        this.caseHeaderItemsOffset = [];
        for (let i = 0; i < caseHeaderItems.length; i++) {
          let item = caseHeaderItems[i];
          this.caseHeaderItemsOffset.push({
            width: item.offsetWidth,
						left: item.offsetLeft
          })
        }
      },
      // 切换tab时，tab滑动到中间位置
			scrollDom(index) {
				if (index !== 0 && !index || !this.caseHeaderItemsOffset[index]) {
					return;
				}
				const offsetLeft = this.caseHeaderItemsOffset[index].left - this.caseHeaderOffsetLeft;
				const scrollLeft = offsetLeft - (this.caseHeaderOffsetWidth - this.caseHeaderItemsOffset[index].width) / 2;
				let scrollToLeft = scrollLeft < 0 ? 0 : scrollLeft;
        let ele = this.$refs.caseHeader[0];
        ele.scrollTo({
          left: scrollToLeft,
          behavior: 'smooth'
        })
			}
    },
    mounted() {
      this.caseCurContent = this.caseList[this.caseCurIndex];
      this.scrollview = this.$refs.scrollview;
      if (this.scrollview) {
        this.scrollview.addEventListener("scroll", this.watchScroll, true);
      }

      if (this.isMobile) {
        this.$nextTick(() => {
          this.initCaseScrollData();
        })
      }
    }
  }
</script>

<style lang="scss">
@import "~style/landing_page.scss";
.ivu-tooltip-inner {
  max-width: 300px;
}
</style>