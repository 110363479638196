
<template>
  <div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="image in imgs" :key="image" class="swiper-slide">
        <img :src="image" alt="" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css';

  export default {
    props: ["imgs", "interval"],
    components: {
      Swiper,
      SwiperSlide
    },
    computed: {
      swiperOption() {
        return {
          spaceBetween: 30,
          centeredSlides: true,
          autoplay: {
            delay: this.interval,
            disableOnInteraction: false
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            bulletClass : 'swiper-pagination-bullet'
          }
        }
      }
    }
  }
</script>

<style>
.swiper {
  padding-bottom: 2rem;
}
.swiper-pagination {
  bottom: 0rem;
}
.swiper-pagination-bullet {
  width: 0.6rem !important;
  height: 0.6rem !important;
  background: #3519FB !important;
}
</style>